/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";
//import Tooltip from "@material-ui/core/Tooltip";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Close from "@material-ui/icons/Close";
//import Check from "@material-ui/icons/Check";
//import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
//import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// @material-ui/icons
//import Camera from "@material-ui/icons/Camera";
//import Remove from "@material-ui/icons/Remove";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import Badge from "components/Badge/Badge.js";

//import ImageUpload from "components/CustomUpload/ImageUpload.js";

//import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//import CachedIcon from '@material-ui/icons/Cached';
//import ArtTrack from "@material-ui/icons/ArtTrack";
//import RotateRightIcon from '@material-ui/icons/RotateRight';

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from '@material-ui/core/';
//import { TextareaAutosize } from '@material-ui/core/';
//import NavPills from "components/NavPills/NavPills.js";

//import Muted from "components/Typography/Muted.js";


import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";


const useStyles = makeStyles(profilePageStyle);

export default function UpdateProductPage() {

  const history = useHistory();
  const [isItemPaner, setItemPaner] = useState([]);
  const [isArticlePaner, setArticlePaner] = useState([]);

  const [isTotalht, setTotalht] = useState([]);
  const [isCalcultva, setCalcultva] = useState([]);
  const [isTotalttc, setTotalttc] = useState([]);
  const [isSetRecordCount, setRecordCount] = useState(0);

  const [isMessageQuantite, setMessageQuantite] = useState('');
  const initializePaner = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eInitializePaner.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          setItemPaner(res.data.initializePanerdata.productName);
          return;
        })
    } catch (error) { throw error; }
  };


  const createPaner = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eCreatePaner.php`, {
        id_std: sessionStorage.getItem("id_std"),
        quantsorti: isQuantite,
      })
        .then(res => {
          if (res.data.success === true) {
            setMessageQuantite(res.data.insertPanerdata.messageForQte);
            showPaner();
          }
          else {
            setMessageQuantite(res.data.insertPanerdata.messageForQte);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const [isQuantite, setQuantite] = useState(1);//1 par defaut

  const changeQuantite = (event) => {
    setQuantite(event.target.value);
  };

  const showPaner = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eShowPaner.php`)
        .then(res => {
          if (res.data.success === true) {
            setRecordCount(res.data.RecordCount);
            setArticlePaner(res.data.showPanerdata);//les articles dans le panier
            setTotalht(res.data.totalht);
            setCalcultva(res.data.calcultva);
            setTotalttc(res.data.totalttc);
          }
          else {
            setMessageQuantite(res.data.showPanerdata);
            setRecordCount(res.data.RecordCount);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const delItemPaner = async (id_pan) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eDelItemPaner.php`, {
        id_pan: id_pan,
      })
        .then(res => {
          if (res.data.success === true) {
          showPaner();
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const deletePaner = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eDeletePaner.php`)
        .then(res => {
          if (res.data.success === true) {
            setMessageQuantite(res.data.deletePanerdata.messageForQte);
            setRecordCount(0);
          }
          else {
            console.log(res.data.deletePanerdata);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const classes = useStyles();
 
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    initializePaner();
  }, []);

  return (
    <div>
      <Header
        color="black"
        brand="EFLEX"
        links={<HeaderLinks dropdownHoverColor="whiteColor" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />

      <br /><br />      <br /><br />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br /><br />      <br /><br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              
              <h4 className={classNames(classes.description, classes.textCenter, classes.textBlue)}>Votre panier</h4>
              <hr></hr>
              <Button
                onClick={() => showPaner()}
                color="info"
                size="sm"
                round
                className={classes.lastButton}
              >
                Voir{"\u00A0"}{"\u00A0"}<ArrowDownwardIcon />
              </Button>
              <Button
                onClick={() => deletePaner()}
                color="info"
                size="sm"
                round
                className={classes.lastButton}
              >
                Vider{"\u00A0"}{"\u00A0"}<DeleteForeverIcon />
              </Button>
             <Link to="/catalogue-page">           
              <Button
                color="info"
                size="sm"
                round
                className={classes.buttonBleu}
              >
                Catalogue{"\u00A0"}{"\u00A0"}<LibraryBooks />
              </Button>
              </Link>
              {isArticlePaner.length !== 0 ? (<> 
                <Link to="/treatmentorder-page">      
              <Button
                color="primary"
                size="sm"
                round
                className={classes.lastButton}
              >
                Valider le Panier
              </Button>
              </Link>
               </>) : (<></>)} 
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Table
                tableHead={[
                  "PRODUIT",
                  "QUANTITE",
                  "AJOUTER",
                ]}

                tableData={[
                  [
                    <span >
                      <a href="#jacket" className={classes.tdNameAnchor}>
                        {isItemPaner}
                      </a>
                    </span>,
                    <span>
                      <TextField className={classes.input}
                        type="text"
                        onChange={changeQuantite}
                        placeholder="0"                     
                        value={isQuantite}
                      />
                    </span>,
                    <span>
                      <div className={classes.buttonGroup}>
                        <Button
                          onClick={() => createPaner()}
                          color="info"
                          size="sm"
                          round
                          className={classes.lastButton}
                        >
                          <ShoppingCart /><Add />
                        </Button>
                      </div>
                    </span>
                  ],
                ]}
                tableShopping
                customHeadCellClasses={[
                  classes.description,
                  classes.description,
                  classes.description
                ]}
                customHeadClassesForCells={[0, 1, 2]}
                customCellClasses={[
                  classes.tdName,
                  classes.customFont,
                  classes.customFont,
                ]}
                customClassesForCells={[0, 1, 2]}
              />

            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br />
                <div className={classes.name}>
                  <h4 className={classes.title}>{isMessageQuantite}</h4>
                 </div>
              </div>
            </GridItem>
          </GridContainer>
          {isSetRecordCount === 0 ? (<></>) : (<> 
            <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
            <span className={classNames(classes.description, classes.textCenter, classes.textBlue)}>{"\u00A0"}{"\u00A0"}Photo</span>    
            <hr></hr>          
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
             <span className={classNames(classes.description, classes.textCenter, classes.textBlue)}>Produit</span>
             <hr></hr>          
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
              <span className={classNames(classes.description, classes.textCenter, classes.textBlue)}>Qte</span>
              <hr></hr>          
             </GridItem>
              <GridItem xs={2} sm={2} md={2}>
              <span className={classNames(classes.description, classes.textCenter, classes.textBlue)}>Prix_ht</span>
              <hr></hr>          
             </GridItem>
             {isArticlePaner.map((item) => (<>
              <GridItem xs={4} sm={4} md={4}>
              <div className={classes.imgContainer} key={item.id_pan}>
                        <img src={`${ApiConfig.baseUrl}/images/${item.image}`} alt="..."  className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                } />
                      </div>
                      <hr></hr>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <span className={classes.textBadge}> {item.productName}</span>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
              <span className={classes.textBadge}> {item.qte}</span>
              </GridItem>
               <GridItem xs={1} sm={1} md={1}>
{/*               <span className={classes.textBnadge}> {item.unitht}</span>,
 */}              <span className={classes.textBadge}> {item.qteUnitht}</span>
 
 <br></br><Button
                      onClick={() => delItemPaner(item.id_pan)}
                        color="danger"
                        size="sm"
                        round
                        className={classes.lastButton}
                      >
                        <Close />
                      </Button>
              </GridItem>
              </>
               ))}
            </GridContainer>
            <Card>
            <CardBody>

          <GridContainer>
               <GridItem xs={4} sm={4} md={4}>
              <span className={classes.textBlue}>TOTAL HT {isTotalht}€</span></GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <span className={classes.textBlue}>TVA {isCalcultva}€</span>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
               <span className={classes.textCaramel}>TOTAL TTC {isTotalttc}€</span>
            </GridItem>
          </GridContainer>
          </CardBody>
          </Card>

 </>)} 
 <div className={classes.space50} />
         
            <Clearfix />
        </div>
      </div>
      <br /><br />
    </div>
  );
}
